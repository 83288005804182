import ApplicationController from "../../../../plus/src/controllers/application_controller";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { debounce, omitBy, isNil, uniqBy } from "lodash";
import { get, post, put, patch, destroy } from "@rails/request.js";
import { DateTime } from "luxon";
import { compact } from "lodash";
import { v4 as uuidv4 } from "uuid";
import flatpickr from "flatpickr";
export class BaseGridController extends ApplicationController {
  tableLayout = "fitData";
  static targets = ["table", "actions"];
  static values = {
    edits: Object,
  };

  async connected() {
    this.setupLuxon();
    this.mediaTypesAndSubtypes = await this.fetchMediaTypesAndSubtypes();
    this.unitScreenSubtypes = await this.fetchUnitScreenSubtypes();
    const response = await this.fetchConfig();
    this.saveDataDebounced = debounce(this.saveData, 100);
    this.deleteDataDebounced = debounce(this.deleteData, 100);
    this.cellEdited = this.cellEdited.bind(this);
    this.rowFormatter = this.rowFormatter.bind(this);
    this.rangePasted = this.rangePasted.bind(this);
    this.getMatchingSubtypes = this.getMatchingSubtypes.bind(this);
    this.getMatchingScreenSubtypes = this.getMatchingScreenSubtypes.bind(this);
    this.handleRowDeletion = this.handleRowsDeletion.bind(this);
    this.editCheck = this.editCheck.bind(this);
    this.interceptPaste = this.interceptPaste.bind(this);
    this.flatpickrEditor = this.flatpickrEditor.bind(this);
    this.openDatePicker = null
    const configuredColumns = this.customizeColumns(response.columns);
    // const data = await this.fetchData();
    // this.tableData = data;
    // Use this property for future optimization where we only PUT the edited cells instead of the whole table
    this.editedCells = [];
    this.deletedCells = [];
    this.initializeTable(configuredColumns, this.tableData);
  }

  disconnect() {
    clearInterval(this.checkEmptyRowsInterval);
  }

  initializeTable(columns, data) {
    this.extendNavigation();
    this.interceptPaste();
    this.table = new Tabulator(this.tableTarget, {
      height: "75vh", // we need a height to make the virtual rendering work, otherwise it will take a long time for big tables
      width: "100%",
      rowHeader: {
        formatter: "rownum",
        headerSort: false,
        hozAlign: "center",
        resizable: false,
        frozen: true,
        width: 40,
      },
      editorEmptyValue: undefined,
      index: "row_id",
      tabEndNewRow: true,
      addRowPos: "bottom",
      // renderHorizontal: "virtual",
      // renderVerticalBuffer: 10,
      history: true,
      selectableRange: true,
      // reactiveData: true,
      // editTriggerEvent: "click",
      editTriggerEvent: "click",
      selectableRangeClearCells: true,
      clipboard: true,
      clipboardCopyStyled: false,
      clipboardCopyConfig: {
        rowHeaders: false,
        columnHeaders: false,
      },
      clipboardCopyRowRange: "range",
      clipboardPasteParser: "range",
      clipboardPasteAction: customClipboardPaste(),
      rowFormatter: this.rowFormatter,
      layout: this.tableLayout,
      ajaxURL: window.location.href, // using this instead of grabbing data with fetchConfig in case we want to paginate this in the future
      // renderVertical: "virtual",
      columns: columns,
      // data: data,
      // Removal still needs to be implemented
      // Will probably add a meta attribute to the row, something like { meta: { deleted: true } }
      // Then on the server check for this attribute and remove the row from the inventory_file.data
      rowContextMenu: [
        {
          label: "<i class='fas fa-trash'></i> Delete Row",
          action: (e, row) => {
            const selectedRows = this.table.getRanges().flatMap(range => range.getRows())
            this.handleRowsDeletion(selectedRows);
          },
        },
      ],

      // debugEventsExternal: true,
      // debugEventsInternal: true
    });

    this.checkEmptyRowsInterval = setInterval(() => {
      this.cleanupEmptyRows();
    }, 1000);

    this.table.on("cellEdited", this.cellEdited);
    this.table.on("clipboardPasted", this.rangePasted);
    // REMOVE THIS BEFORE PRODUCTION
    window.table = this.table;
    window.tableData = this.tableData;
  }

  interceptPaste() {
    this.tableTarget.addEventListener(
      "paste",
      event => {
        const activeElement = document.activeElement;
        if (activeElement.tagName === "INPUT" && this.table.modules.edit.currentCell) {
          const cell = this.table.modules.edit.currentCell;
          if (this.openDatePicker) { this.openDatePicker.close() }
          activeElement.blur();
          const newEvent = new event.constructor(event.type, event);
          cell.element.dispatchEvent(newEvent);
          return;
        }
      },
      true,
    );
  }

  extendNavigation() {
    Tabulator.extendModule("keybindings", "actions", {
      rangeExpandLeft: function(e) {
        if (this.table.modules.edit.currentCell) {
          return;
        }

        this.dispatch("keybinding-nav-range", e, "left", false, true);
      },
      rangeExpandRight: function(e) {
        if (this.table.modules.edit.currentCell) {
          return;
        }

        this.dispatch("keybinding-nav-range", e, "right", false, true);
      },
      navLeft: function(e) {
        if (this.table.modules.edit.currentCell) {
          return;
        }

        this.dispatch("keybinding-nav-left", e);
      },
      navRight: function(e) {
        if (this.table.modules.edit.currentCell) {
          return;
        }

        this.dispatch("keybinding-nav-right", e);
      },
    });
  }

  customizeColumns(columns) {
    return columns.map(column => {
      if (column.field === "unit_subtype") {
        column.editorParams = { valuesLookup: this.getMatchingSubtypes };
      }

      if (column.field === "screen_subtype") {
        column.editorParams = { valuesLookup: this.getMatchingScreenSubtypes };
      }

      // Check the columns returned by the backend for date columns
      // Relevant controllers:
      // - app/controllers/pro/tasks/supplier_task_base_grid_controller.rb
      // - app/controllers/pro/tasks/supplier_task_unit_details_controller.rb
      const dateColumns = ["start_date", "end_date", "design_asset_due_date"];

      if (dateColumns.includes(column.field)) {
        column.editor = this.flatpickrEditor;
      }

      return column;
    });
  }

  flatpickrEditor(cell, onRendered, success, cancel, editorParams) {
    const editor = document.createElement("input");
    const value = cell.getValue();
    if (value) {
      editor.value = value;
    }

    const picker = flatpickr(editor, {
      dateFormat: "m/d/Y",
      onClose: (selectedDates, dateStr, instance) => {
        success(dateStr);
        instance.destroy();
        editor.blur()
        this.openDatePicker = null
      },
    });
    this.openDatePicker = picker
    onRendered(() => {
      picker.element.focus();
    });

    // picker.calendarContainer.addEventListener("blur", () => {
    //   picker.close();
    // });
    return editor;
  }

  // If the cell is a package, it should not be editable
  // Fallback to value configured by the backend column definition
  editCheck(defaultValue) {
    return cell => {
      const data = cell.getData();
      if (data.is_package) {
        cell.getElement().classList.add("gray-cell");
        return false;
      }
      return defaultValue;
    };
  }

  getMatchingSubtypes(cell) {
    const unitType = cell
      .getRow()
      .getCell("unit_type")
      .getValue();
    const mediaType = this.mediaTypesAndSubtypes.find(data => {
      return data.name.toLowerCase() === unitType.toLowerCase();
    });
    if (mediaType) {
      return mediaType.media_subtypes.map(subtype => {
        return subtype.name;
      });
    } else {
      return [];
    }
  }

  getMatchingScreenSubtypes(cell) {
    const screenType = cell
      .getRow()
      .getCell("screen_type")
      .getValue();
    const screenSubtypes = this.unitScreenSubtypes.filter(data => {
      return data.screen_type.toLowerCase() === screenType.toLowerCase();
    });

    if (screenSubtypes) {
      return screenSubtypes.map(subtype => {
        return subtype.name;
      });
    } else {
      return [];
    }
  }

  rowFormatter(row) {
    const data = row.getData();
    const errors = data.errors;
    if (!errors || !data.row_id) {
      return;
    }
    this.table.blockRedraw();
    row.getCells().forEach(cell => {
      cell.getElement().classList.remove("cell-error");
    });
    errors.map(error => {
      const cellWithError = row.getCell(error.key);
      if (cellWithError) {
        cellWithError.getElement().classList.add("cell-error");
      }
    });

    this.table.restoreRedraw();
  }

  cellEdited(cell) {
    const data = cell.getData();
    // If the row is empty, we don't want to save it
    // The cleanupEmptyRows function will take care of removing it
    if (this.hasOnlyErrors(data)) {
      return;
    }
    this.editedCells.push(data);
    this.editedCells = uniqBy(this.editedCells, "row_id");
    const currentValue = String(cell.getValue());
    const oldValue = String(cell.getOldValue());
    if (currentValue !== oldValue) {
      this.saveDataDebounced(true);
    }
  }

  handleRowsDeletion(rows) {
    this.table.blockRedraw()
    const newRows = rows.map(row => {
      this.deletedCells.push(row.getData());
      return { row_id: uuidv4() }
    })
    this.table.deleteRow(rows)
    this.table.updateOrAddData(newRows)
    this.deleteDataDebounced(true);
    this.table.restoreRedraw();
  }

  cleanupEmptyRows() {
    const data = this.table.getData()
    const emptyRows = data.filter(row => {
      return this.hasOnlyErrors(row)
    })
    const rowIndexes = emptyRows.map(row => row.row_id)
    const rows = rowIndexes.map(rowId => this.table.getRow(rowId))
    if (rows.length > 0) {
      this.handleRowsDeletion(rows)
    }
  }

  rangePasted(_data) {
    this.saveDataDebounced(true);
  }

  // temporary = true will save invalid data and stay on the page
  // temporary = false will still save but if there are no errors will redirect to the next page
  async saveData(temporary = false) {
    if (this.editedCells.length === 0 && temporary) {
      return;
    }
    const dataWithoutErrors = this.editedCells.map(({ errors, placeholderRow, ...rest }) => rest);
    const nonEmptyRows = dataWithoutErrors.filter(row => compact(Object.values(row)).length > 0);
    const response = await put(window.location.href, { body: { data: nonEmptyRows, temporary_save: temporary } });
    if (response.ok) {
      // If temporary save, update the table with the new data
      // otherwise do nothing until job is done and broadcasts the turbo visit to next step
      if (temporary) {
        const data = await response.json;
        this.editedCells = [];
        this.deletedCells = [];
        this.table.blockRedraw();
        // remove padded rows that have only row_id
        // Object.keys(row).toString() hack because on javascript ['row_id'] == ['row_id'] is false, even with ===
        const dataWithoutFillerRows = data.data.filter(row => Object.keys(row).toString() != "row_id");
        if (dataWithoutFillerRows.length > 0) {
          await this.table.updateData(dataWithoutFillerRows);
        }
      }
      this.table.restoreRedraw();
      return true;
    } else {
      console.error({ message: "Failed to save data", response });
      return false;
    }
  }

  async deleteData() {
    if (this.deletedCells.length === 0) {
      return;
    }
    const response = await destroy(window.location.href, { body: { data: this.deletedCells } });
    if (response.ok) {
      const data = await response.json;
      this.deletedCells = [];
      // this.table.blockRedraw();
      // remove padded rows that have only row_id
      // Object.keys(row).toString() hack because on javascript ['row_id'] == ['row_id'] is false, even with ===
      // const dataWithoutFillerRows = data.data.filter(row => Object.keys(row).toString() != "row_id");
      // await this.table.updateData(dataWithoutFillerRows);
      // this.table.restoreRedraw();
    } else {
      console.error({ message: "Failed to delete data", response });
    }
  }

  // ------ Stimulus Action
  async saveAndContinue(e) {
    // Manually disable the button to prevent double submission
    // If the form was submitted directly with turbo instead of a custom fetch this would be handled automatically
    // if the button had data-disable-with="Processing..." attribute
    const currentText = e.submitter.innerHTML;
    e.submitter.disabled = true;
    e.submitter.innerHTML = "Processing...";
    const saveResult = await this.saveData();
    if (!saveResult) {
      e.submitter.disabled = false;
      e.submitter.innerHTML = currentText;
    }
  }

  async scrollTo(e) {
    const target = e.currentTarget;
    const rowId = target.dataset.rowId;
    const row = this.table.getRow(rowId);
    // sometimes when a row that is too far from the scrolled position its not rendered yet and tabulator cant scroll to it
    if (!row) {
      return;
    }
    const cell = row.getCell(target.dataset.key);
    await this.table.scrollToRow(rowId, "top", true);
    // check app/views/pro/tasks/_side_panel.html.haml for the data attributes
    // attention: some col errors will point to calculated values so the cell.edit() needs to point to the data source cell
    // for example, `price` errors on short flight campaigns need to point to the `price_for_duration` cell
    cell.edit();
  }

  // END ------ Stimulus Action

  async fetchConfig() {
    // instead of window.location could also pass as a config to this controller
    // since it will only be used here I will not do it for now
    const response = await get(window.location.href, { responseKind: "json", query: { config: true } });
    if (response.ok) {
      const data = await response.json;
      return data;
    }
    throw new Error("Failed to fetch config");
  }

  async fetchMediaTypesAndSubtypes() {
    const response = await get("/api/v1/media_types/all_subtypes", { responseKind: "json" });
    if (response.ok) {
      const data = await response.json;
      return data.data.media_types;
    }
    throw new Error("Failed to fetch unit subtypes");
  }

  async fetchUnitScreenSubtypes() {
    const response = await get("/api/v1/media_types/screen_subtypes", { responseKind: "json" });
    if (response.ok) {
      const data = await response.json;
      return data.data.screen_subtypes;
    }
    throw new Error("Failed to fetch unit screen subtypes");
  }

  async fetchData() {
    // instead of window.location could also pass as a config to this controller
    // since it will only be used here I will not do it for now
    const response = await get(window.location.href, { responseKind: "json" });
    if (response.ok) {
      const data = await response.json;
      return data;
    }
    throw new Error("Failed to fetch data");
  }
  setupLuxon() {
    if (!window.DateTime) {
      window.DateTime = DateTime;
    }
    if (!window.luxon) {
      window.luxon = DateTime;
    }
  }

  isEmptyRow(row) {
    const keys = Object.keys(omitBy(row, isNil));

    // keys.toString() hack because on javascript ['row_id'] == ['row_id'] is false, even with ===
    return keys.toString() == "row_id";
  }

  hasOnlyErrors(row) {
    const keys = Object.keys(omitBy(row, isNil));

    // keys.toString() hack because on javascript ['row_id'] == ['row_id'] is false, even with ===
    return keys.toString() == "row_id,errors";
  }
}

function customClipboardPaste() {
  // Copied from https://github.com/olifolkerd/tabulator/issues/4398#issuecomment-2081106994
  return function(data) {
    var rows = [],
      range = this.table.modules.selectRange.activeRange,
      singleCell = false,
      bounds,
      startCell,
      startRow,
      rowWidth,
      dataLength;

    dataLength = data.length;
    const lastRow = this.table
      .getRows()
      .findLast(() => true)
      .getPosition();
    const startPosition = this.table.modules.selectRange.activeRange.start.row;

    // My custom code to add rows if the pasted data is bigger than the current table
    // The rest is copied as is from the link above
    if (dataLength > lastRow - startPosition) {
      const rowsToAdd = dataLength - (lastRow - startPosition);
      this.table.blockRedraw();
      for (let i = 0; i < rowsToAdd; i++) {
        // new row needs a row_id so updateData can update the correct row
        this.table.addRow({ row_id: uuidv4() });
      }
      this.table.restoreRedraw();
    }

    if (range) {
      bounds = range.getBounds();
      startCell = bounds.start;

      if (bounds.start === bounds.end) {
        singleCell = true;
      }

      if (startCell) {
        rows = this.table.rowManager.activeRows.slice();
        startRow = rows.indexOf(startCell.row);

        if (singleCell) {
          rowWidth = data.length;
        } else {
          rowWidth = rows.indexOf(bounds.end.row) - startRow + 1;
        }

        if (startRow > -1) {
          this.table.blockRedraw();

          rows = rows.slice(startRow, startRow + rowWidth);

          rows.forEach((row, i) => {
            const cellsNewData = data[i % dataLength];
            // const cellsNewData = data[i];
            Object.entries(cellsNewData).forEach(([field, value]) => {
              const cell = row.getCell(field);
              if (cell) {
                cell.setValue(value);
                // cell.component.setEdited();  // Requires https://github.com/olifolkerd/tabulator/pull/4485
              }
            });
          });

          this.table.restoreRedraw();
        }
      }
    }

    return rows;
  };
}

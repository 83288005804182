import ApplicationController from "../../../plus/src/controllers/application_controller";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import Dashboard from "@uppy/dashboard";
import "@uppy/dashboard/dist/style.min.css";

export class UppyController extends ApplicationController {
  static values = {
    directory: { type: String, default: "user_uploads" },
    directUpload: { type: Boolean, default: true },
    multiFile: { type: Boolean, default: false },
    fileTypes: { type: Array, default: ["image/*", "video/*", "application/pdf", "application/zip"] },
    note: { type: String, default: "Supported file type: PDF, JPG, PNG, TIFF, ZIP, MP4, MOV, MPEG." },
  };

  static targets = ["fileInput", "container"];
  connected() {
    let maxFiles;
    let uppyTarget;
    if (this.hasContainerTarget) {
      uppyTarget = this.containerTarget;
    } else {
      uppyTarget = this.element;
    }
    if (this.multiFileValue) {
      // TODO: make this configurable
      // realisticaly I dont think anyone would want to upload more than 50 files at once
      // if really needed extract the restriction config to an object without maxNumberOfFiles
      maxFiles = 50;
    } else {
      maxFiles = 1;
    }
    const uppy = new Uppy({
      autoProceed: true,
      allowMultipleUploadBatches: this.multiFileValue,
      restrictions: {
        maxNumberOfFiles: maxFiles,
        allowedFileTypes: this.fileTypesValue,
      },
    });
    uppy.use(Dashboard, {
      inline: true,
      target: uppyTarget,
      width: 550,
      height: 403,
      proudlyDisplayPoweredByUppy: false,
      note: this.noteValue,
    });
    if (this.directUploadValue) {
      uppy
        .use(AwsS3, {
          shouldUseMultipart: file => true,
          companionUrl: "/",
          companionHeaders: {
            directory: this.directoryValue
          },
        })
        .on("complete", result => {
          const uploadedFiles = result.successful.map(file => {
            const parsed = new URL(file.response.body.location);
            return {
              path: parsed.pathname.substring(1), // remove leading /
              file_type: file.type,
              file_name: file.name,
              file_size: file.size,
              file_full_path: parsed.pathname,
            };
          });

          // TODO maybe allow for custom field names
          uploadedFiles.map(f => {
            this.element.appendChild(this.createInput("uploads", "file_path", f.path));
            this.element.appendChild(this.createInput("uploads", "file_type", f.file_type));
            this.element.appendChild(this.createInput("uploads", "file_name", f.file_name));
            this.element.appendChild(this.createInput("uploads", "file_size", f.file_size));
          });
        });
    } else {
      // If not direct upload, we need to add the file input to the form
      // TODO: handle multiple files by creating multiple inputs
      uppy.on("complete", result => {
        let file = result.successful[0].data
        let container = new DataTransfer();
        container.items.add(file);
        this.fileInputTarget.files = container.files;
        // Dispatch change on the form so this controller plays nice with other controllers that listen to change events
        this.element.dispatchEvent(new Event("change", { bubbles: true }));
      })
      uppy.on("file-removed", _file => {
        this.fileInputTarget.value = null;
        // Dispatch change on the form so this controller plays nice with other controllers that listen to change events
        this.element.dispatchEvent(new Event("change", { bubbles: true }));
      })
    }
  }

  createInput(inputName, paramName, value) {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = `${inputName}[]${paramName}`;

    input.value = value;
    return input;
  }
}

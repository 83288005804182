import morphdom from "morphdom";
import Mousetrap from "mousetrap";
import { sortBy, isEmpty } from "lodash";
import { Controller } from "stimulus";

export default class extends Controller {
  debug = false;
  static targets = ["input", "dropdown", "option"];
  static values = {
    selected: Object,
    options: Array,
    cursor: Number,
    showDropdown: Boolean,
    options: Array,
    search: String,
    placeholder: String,
    addAction: String,
    onChangeCall: String,
    onChangeElement: String,
    dropdownIcon: String,
  };

  connect() {
    this.render();
  }

  globalAfterReflex() {
    this.render();
  }

  render() {
    if (this.debug) console.log("rendered");
    let selected;
    if (isEmpty(this.selectedValue)) {
      selected = null;
    } else {
      selected = this.selectedValue;
    }
    const filteredOptions = this.getFilteredOptions(selected, this.optionsValue);
    const sortedOptions = sortBy(filteredOptions, "name");
    const placeholder = this.placeholderValue;
    const cursor = this.cursorValue;
    const showDropdown = this.showDropdownValue;
    const inputWidth = this.element.offsetWidth;
    const dropdownWidth = Math.max(inputWidth, 260);

    let content = `
      <div>
        <div class="single-select__inner">
          ${
            selected
              ? `
            <div
              class='selected-option'
              data-id='${selected.id}'
            >
              ${selected.name}
            </div>`
              : "<div class='selected-option'></div>"
          }

          <input
            type="text"
            class="mousetrap"
            data-single-select-target="input"
            placeholder="${selected ? "" : placeholder}"
            data-action="
              keydown->single-select#onInputKeydown
              input->single-select#search
              focus->single-select#showDropdown
            "
            value="${this.searchValue}"
          />
          ${
            selected
              ? `<div class="close" data-action="click->single-select#clickOnRemove">
              <i class="fa fa-times"></i>
            </div>`
              : `<div class="caret">
              <i class="fa ${this.dropdownIconValue}"></i>
            </div>`
          }
        </div>

        <div
          class="dropdown"
          data-single-select-target="dropdown"
          style="
            ${showDropdown ? "" : "display: none"};
            width: ${dropdownWidth}px;
            "
        >
          ${sortedOptions
            .map((item, index) => {
              return `
              <div
                class='option ${index === cursor ? `active` : ""}'
                data-single-select-target='option'
                data-id="${item.id}"
                data-name="${item.name}"
                data-action='${this.addActionValue} click->single-select#clickOnOption'>
                ${item.name}
              </div>`;
            })
            .join("")}

          ${sortedOptions.length === 0 ? `<div class="no-options">No options</div>` : ""}
        </div>
      </div>
      `;

    morphdom(this.element, content, { childrenOnly: true });
    const newHeight = $(this.element).height();
    $(this.dropdownTarget).css("top", newHeight + 4 + "px");
  }

  showDropdown() {
    if (this.debug) console.log("show dropdown");
    this.showDropdownValue = true;
    this.cursorValue = 0;
    this.render();
    Mousetrap.bind("enter", this.hitEnterOnOption.bind(this));
    Mousetrap.bind("down", this.hitDownArrow.bind(this));
    Mousetrap.bind("up", this.hitUpArrow.bind(this));
    Mousetrap.bind("esc", this.hideDropdown.bind(this));
    Mousetrap.bind("backspace", this.hitBackspace.bind(this));
  }

  hideDropdown() {
    if (this.debug) console.log("hide dropdown");
    this.showDropdownValue = false;
    $(this.dropdownTarget).hide();
    Mousetrap.unbind("enter");
    Mousetrap.unbind("down");
    Mousetrap.unbind("up");
    Mousetrap.unbind("esc");
    Mousetrap.unbind("backspace");
  }

  // --- User Actions ---
  search(e) {
    this.searchValue = this.inputTarget.value;
    this.cursorValue = 0;
    this.render();
  }

  onInputKeydown(e) {
    if (e.key === "Backspace") {
      this.removeSelected();
      this.showDropdown();
    }
  }

  clickOnBody(e) {
    if (this.debug) console.log("clickOnBody");
    if (this.element.contains(e.target)) {
      return;
    }
    if (this.showDropdownValue) this.hideDropdown();
  }

  clickOnOption(e) {
    e.preventDefault();
    e.stopPropagation();
    const el = e.currentTarget;
    this.selectOptionFromElement(el);
    this.hideDropdown();
  }

  clickOnRemove(e) {
    e.preventDefault();
    e.stopPropagation();
    this.removeSelected();
  }

  // --- Handle Keyboard Shortcuts
  hitBackspace() {
    // todo: check if cursor is out of bounds, then move it if yes.
    if (!this.searchValue && this.selectedValue) {
      this.removeSelected();
    }
  }

  hitEnterOnOption() {
    const el = this.getCurrentCursorElement();
    if (!el) return;
    this.selectOptionFromElement(el);
    this.hideDropdown();
  }

  hitUpArrow(e) {
    e.preventDefault();
    if (this.debug) console.log("move up");
    let newCursor = this.cursorValue - 1;
    if (newCursor < 0) newCursor = this.optionsValue.length - 1;
    if (this.debug) console.log("new cursor", newCursor);
    this.cursorValue = newCursor;
    this.render();
    this.scrollParentToChild(this.getCurrentCursorElement());
  }

  hitDownArrow(e) {
    e.preventDefault();
    // todo: disallow out of bounds cursor
    if (this.debug) console.log("move down");
    let newCursor = this.cursorValue + 1;
    const optionsLength = this.optionsValue.length - 1;
    if (newCursor > optionsLength) newCursor = 0;
    this.cursorValue = newCursor;
    this.render();
    this.scrollParentToChild(this.getCurrentCursorElement());
  }

  // --- Private Methods ---
  scrollParentToChild(child) {
    if (!child) return;
    const parent = child.parentElement;
    if (this.debug) console.log(parent);
    const parentRect = parent.getBoundingClientRect();
    const parentViewableArea = {
      height: parent.clientHeight,
      width: parent.clientWidth,
    };
    const childRect = child.getBoundingClientRect();
    const isViewable = childRect.top >= parentRect.top && childRect.top <= parentRect.top + parentViewableArea.height;
    if (!isViewable) {
      if (this.debug) console.log("not viewable");
      parent.scrollTop = childRect.top + parent.scrollTop - parentRect.top;
    }
  }

  getFilteredOptions(selected, options) {
    const search = this.searchValue;
    if (selected) {
      options = options.filter(item => item.id != selected.id);
    }
    if (!search) return options;
    return options.filter(item => {
      return item.name.toLowerCase().includes(search.toLowerCase());
    });
  }

  getCurrentCursorElement() {
    return $(this.dropdownTarget).children(".active")[0];
  }

  removeSelected() {
    if (isEmpty(this.selectedValue)) return;
    if (!this.optionsValue.find(item => item.id === this.selectedValue.id)) {
      this.optionsValue = this.optionsValue.concat(this.selectedValue);
    }
    this.selectedValue = {};
    this.renderWithClearedAndFocusedInput();
    this.callOnChange();
  }

  renderWithClearedAndFocusedInput() {
    this.searchValue = "";
    this.render();
    this.inputTarget.focus();
  }

  selectOptionFromElement(el) {
    const { name, id } = el.dataset;
    const parsedId = isNaN(Number(id)) ? id : Number(id);

    if (!isEmpty(this.selectedValue)) {
      if (!this.optionsValue.find(item => item.id === this.selectedValue.id)) {
        this.optionsValue = this.optionsValue.concat(this.selectedValue);
      }
    }
    this.selectedValue = { name, id };
    this.optionsValue = this.optionsValue.filter(items => items.id !== parsedId);
    this.renderWithClearedAndFocusedInput();
    this.callOnChange();
  }

  callOnChange() {
    var event = new CustomEvent("change", { detail: this.selectedValue });
    this.element.dispatchEvent(event);
  }
}
